
:root{
    --swiper-theme-color:#25AEE1 !important;
}

.swiper {
    position: relative;
    height: 97vh;
    width: 100vw;
}

.swiper-slide {
text-align: center;
font-size: 18px;
background: #fff;
display: flex;
justify-content: center;
align-items: center;
width: 100% !important;
margin: 0 !important;
border-bottom: 7px solid;
border-image-source: url('../../assets/img/pattern.png');
border-image-slice: 30;
}

.swiper-slide img {
display: block;
object-fit: cover;
}

.autoplay-progress {
position: absolute;
right: 16px;
bottom: 16px;
z-index: 10;
width: 48px;
height: 48px;
display: flex;
align-items: center;
justify-content: center;
font-weight: bold;
color: var(---theme-color);
}

.autoplay-progress svg {
--progress: 0;
position: absolute;
left: 0;
top: 0px;
z-index: 10;
width: 100%;
height: 100%;
stroke-width: 4px;
stroke: var(--swiper-theme-color);
fill: none;
stroke-dashoffset: calc(125.6 * (1 - var(--progress)));
stroke-dasharray: 125.6;
transform: rotate(-90deg);
}

@media only screen and (max-width: 420px) {
    .swiper {
        height: 85vh;
    }
  }